import instance from "./main";

  const fetchChatList = async () => {
    let allChats = [];  
    let currentPage = 1;
    let totalPages = 1;  
    let systemChats = [];
  
    while (currentPage <= totalPages) {
      const response = await instance.get('/bx_block_chat/chats/mychats', {
        params: { page_no: currentPage },
      });
      allChats = [...allChats, ...response.data.data.other_user_chat];
      totalPages = response.data.meta.total_pages;  
      currentPage += 1;  

      systemChats = response.data.data.system_user_chat;
    }
    const allChatsCombined = [...systemChats, ...allChats];
    return allChatsCombined;
  }
 
  const msgHistory = (chatId,currentPage) => {
    return instance.get('/bx_block_chat/messages/chat_history', {
      params: {
        chat_id: chatId,
        page_no: currentPage,
      }
    });
  };
  const fetchPrivateChat = async (chatId) => {
    try {
        const response = await instance.get(`/bx_block_chat/chats/${chatId}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching private chat with ID ${chatId}:`, error.response || error.message);
        throw new Error('Failed to fetch private chat.');
    }
};
  const markMessagesAsRead = async (chat_id) => {
    try {
      const response = await instance.post(`/bx_block_chat/chats/read_messages`, {
        chat_id: chat_id, 
      });
      if (response.status === 200) {
        return response.data
      } else {
        console.error('Unexpected status:', response.status);
      }
    } catch (error) {
      console.error('Error marking messages as read:', error);
    }
  };

  const sendMessage = async (chatId, messageContent) => {
    try {
      const response = await instance.post(`/bx_block_chat/messages`,
        {
          message: {
            message: messageContent
          }
        },
        {
          params: { chat_id: chatId }
        }
      );
      return response.data;
    } catch (error) {
      console.error(`Error while sending private message to chat with ID ${chatId}:`, error.response || error.message);
      throw new Error('Failed to send message to private chat.');
    }
  };

  const fetchAccountsForChat = async () => {
    let allData = [];
    let currentPage = 1;
    let totalPages = 1;

    try {
        while (currentPage <= totalPages) {
            const response = await instance.get(`/bx_block_chat/chats/fetch_accounts?page=${currentPage}`);
            const { data, meta } = response.data;

            allData = allData.concat(data);

            totalPages = meta.total_pages;
            currentPage++;
        }

        return allData;
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    }
  };

export {fetchChatList, fetchPrivateChat,markMessagesAsRead,msgHistory,sendMessage, fetchAccountsForChat}