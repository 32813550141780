import React, { useState } from "react";
import closeIcon from "../../../public/images/close_icon.png"
import searchIcon from '../../../public/images/search.png';

function StartNewMessage ({isOpen, onCloseNewMessage, accountsForChat, onShowChat}) {

    const [userSearch, setUserSearch] = useState('')

    const closeNewMessage = () => {
        onCloseNewMessage(false)
    }

    const showChat = (chat) => {
        
        const {chat_id, image, full_name} = chat.attributes
        const chatData = {
            id: chat_id,
            imageUrl: image,
            name: full_name
        };
        onShowChat(chatData)
        closeNewMessage()
        console.log('chat', chatData)
    }

    return(

        <div className="newMessageContainer">
            <div className="newMessageTopSection">
                <p>New message</p>
                <button className="newMessageCloseButton" onClick={()=> closeNewMessage()}>
                    <img src={closeIcon}></img>
                </button>
            </div>
            <div className="inputField">
                <img src={searchIcon} alt="search" />
                <input
                    placeholder="Search users"
                />
            </div>

            <ul className="newMessageOptions">
                {accountsForChat.map((acc)=>(
                    <li key={acc.id} className="newMessageOptionsItem" onClick={()=>{showChat(acc)}}>
                        <img className="dashBoardImg"></img>
                        <p>{acc.attributes.full_name}</p>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default StartNewMessage;